<template>
  <v-container>
    <v-row class="mb-12 mt-12" align="center" justify="center">
      <h2>{{ $t('title.selectInsuranceType') }}</h2>
    </v-row>
    <v-row align="center" justify="center">
      <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
        <v-hover v-slot="{ hover }">
          <v-card
            :disabled="!checkPipeline(card.label)"
            :elevation="hover ? 16 : 8"
            :class="{ 'on-hover': hover }"
            :to="card.to"
          >
            <v-card-title>
              <v-row class="d-flex flex-column">
                <!-- Icon on the left -->
                <v-col class="d-flex justify-start">
                  <v-icon large left></v-icon>
                </v-col>
                <!-- Subtitle/Text -->
                <v-col class="d-flex justify-start">
                  <span v-text="card.subtitle"></span>
                </v-col>
              </v-row>
            </v-card-title>
            <!-- <v-card-title v-text="card.subtitle">

            </v-card-title> -->
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'

export default {
  data: () => ({
    transparent: 'rgba(255, 255, 255, 0)',
  }),
  computed: {
    ...mapGetters({
      checkPipeline: 'user/checkPipeline',
    }),
    cards() {
      return [
        {
          label: 'CI',
          title: i18n.t('sideBarLinks.CI'),
          subtitle: i18n.t('sideBarLinksTooltips.CI'),
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 5,
          to: '/cidashboard',
        },
        {
          label: 'CMR',
          title: i18n.t('sideBarLinks.CMR'),
          subtitle: i18n.t('sideBarLinksTooltips.CMR'),
          // subtitle: 'Įvykiai, kai apgadintas krovinys',
          src: require('../assets/images/home/CMR.jpg'),
          flex: 5,
          to: '/cmrdashboard',
        },
        {
          label: 'MTPL',
          title: i18n.t('sideBarLinks.MTPL'),
          subtitle: i18n.t('sideBarLinksTooltips.MTPL'),
          // subtitle: 'Civilinės atsakomybės įvykiai, kuriuose esate kalti',
          src: require('../assets/images/home/MTPL.jpg'),
          flex: 5,
          to: '/mtpldashboard',
        },
        {
          label: 'MTPL Injured',
          title: i18n.t('sideBarLinks["MTPL Injured"]'),
          subtitle: i18n.t('sideBarLinksTooltips["MTPL Injured"]'),
          // subtitle: 'Civilinės atsakomybės įvykiai, kuriuose esate nukentėję',
          src: require('../assets/images/home/MTPLI.jpg'),
          flex: 5,
          to: '/mtplinjureddashboard',
        },
        {
          label: 'CASCO',
          title: i18n.t('sideBarLinks.CASCO'),
          subtitle: i18n.t('sideBarLinksTooltips.CASCO'),
          // subtitle: 'KASKO žala Jūsų transporto priemonei',
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 5,
          to: '/cascodashboard',
        },
        {
          label: 'KRD/NA',
          title: i18n.t('sideBarLinks["KRD/NA"]'),
          subtitle: i18n.t('sideBarLinksTooltips["KRD/NA"]'),
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 5,
          to: '/krdnadashboard',
        },
        {
          label: 'CP',
          title: i18n.t('sideBarLinks.CP'),
          subtitle: i18n.t('sideBarLinksTooltips.CP'),
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 5,
          to: '/cpdashboard',
        },
        {
          label: 'BCA',
          title: i18n.t('sideBarLinks.BCA'),
          subtitle: i18n.t('sideBarLinksTooltips.BCA'),
          src: require('../assets/images/home/CASCO.jpg'),
          flex: 5,
          to: '/bcadashboard',
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.6;
}

.v-card.on-hover {
  color: rgb(236, 123, 41);
  box-shadow: 0px 3px 1px -2px rgba(236, 123, 41, 0.6),
    0px 2px 2px 0px rgba(236, 123, 41, 0.6),
    0px 1px 5px 0px rgba(236, 123, 41, 0.6) !important;
}
.on-hover.v-icon {
  color: rgb(236, 123, 41);
  /* 236, 123, 41   #EC7B29*/
}

.on-hover i {
  color: rgb(236, 123, 41);
}
</style>
